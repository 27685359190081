import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Tony’s N.Y. Pizza",
  pageDescription:
    "See how an authentic New York pizzeria in Florida attracted more customers and boosted sales when they implemented SpotOn's restaurant POS system.",
  title: "Selling more slices of pizza",
  subTitle: `How Tony's N.Y. Pizza brought in more customers and sales with SpotOn.`,
  businessName: "Tony’s N.Y. Pizza",
  industry: "Restaurants & hospitality, casual restaurant",
  location: "Fort Myers, Florida",
  products: [
    spotonProducts.RESTAURANT,
    spotonProducts.ORDER,
    spotonProducts.QR,
  ],
  description:
    "In 2019, Chuck Conrose brought a taste of New York to Florida when he opened Tony's N.Y. pizza in Fort Meyers. And with the help of SpotOn technology, he's been able to expand his customer reach, build a loyal following, and increase monthly sales.",
  quoteData: {
    imgName: "tonys-pizza.png",
    quote: `“Customers can come into the restaurant, scan a QR code, order, and pay for their food using their phones. Everything can be done online while they're in the restaurant. That's a huge help to my staff. That's an extra minute a server can spend at another table explaining the menu or cleaning a table. It’s beneficial for us.”`,
    personName: "Chuck Conrose",
    personTitle: "Owner, Tony's N.Y. Pizza",
  },
  goal: `To counteract rising food and labor costs, Conrose wanted to grow sales without over-extending his staff. Also, with third-party delivery services taking 30% of his revenue, he needed a way for guests to order online without the exorbitant fees.`,
  solution: `Tony’s gained more exposure by integrating SpotOn Order with Google. And they increased sales by enabling customers to order and pay with QR codes. On top of that, SpotOn built Tony's an online ordering platform to bypass third-party fees.`,
  results: {
    title: `The results`,
    stats: [
      { title: `4%`, description: `increase in new customers` },
      {
        title: `$40k`,
        description: `increase in monthly sales`,
      },
      { title: `$180k`, description: `in online orders over 4 months` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "A bigger slice of the pie",
    imageName: "bella-pizza.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-05",
    href: "/case-studies/bella-pizza",
    goals: [
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
    ],
  },
  {
    title: "Attracting more fast-casual vegan customers",
    imageName: "majani-soulful-vegan-cuisine.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-07",
    href: "/case-studies/majani-soulful-vegan-cuisine",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
  {
    title: "Hot pizza after cold ski days",
    imageName: "base-camp-pizza.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-09",
    href: "/case-studies/base-camp-pizza",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
];
